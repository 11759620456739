<template>
  <div class='container'>
    <HeaderNav />
    <div
      class="inner"
      v-html="richTextContent"
    >

    </div>
  </div>
</template>
<style scoped lang="scss">
.container {
  position: relative;
  padding-top: 0px;
  padding-bottom: 70px;

  .inner {
    width: 1200px;
    box-sizing: border-box;
    padding: 20px 40px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 6px 10px 17px 0px rgba(1, 69, 133, 0.21);
    margin: 0 auto;
  }
}
</style>
<script>
import HeaderNav from "@/components/HeaderNav"
import areas from "@/utils/area"
import {
  getService,
  getPersonalInfo,
  getWebIntro,
  getHelp
} from "@/request/homepage"
export default {
  name: 'RichText',
  metaInfo() {
    return {
      title: this.tileName
    }
  },
  props: {

  },
  data() {
    return {
      richTextContent: "",
      tileName: '帮助'
    }
  },
  computed: {

  },
  created() {
    this.richTextType = this.$route.query.richTextType.toString()
    this.getData(this.richTextType)
  },
  mounted() {

  },
  watch: {

  },
  methods: {
    getData(type) {
      switch (type) {
        case "0":
          this.tileName = '服务支持'
          getService().then(res => {
            this.richTextContent = res.data.content
          })
          break
        case "1":
          this.tileName = '隐私声明'
          getPersonalInfo().then(res => {
            this.richTextContent = res.data.content
          })
          break
        case "2":
          this.tileName = '网站简介'
          getWebIntro().then(res => {
            this.richTextContent = res.data.content
          })
          break
        case "3":
          this.tileName = '帮助'
          getHelp().then(res => {
            this.richTextContent = res.data.content
          })
          break
      }
    }

  },
  components: {
    HeaderNav
  },
};
</script>